<div class=""
     ngbDropdown
     #exportResultsDropdown="ngbDropdown"
     container="body"
     placement="bottom-end"
     autoClose="outside"
>
    <span class="header-icon fa-3x fa-layers fa-fw dropdown-non-toggle-arrow"
          ngbTooltip="Click to open My Export"
          id="exportResults"
          container="body"
          ngbDropdownToggle
    >
        <fa-icon [icon]="'file-export'"></fa-icon>
        <span class="fa-layers-counter"
              *ngIf="exportResultsService.itemsCount > 0"
              [ngStyle]="{ 'background-color': exportResultsService.hasDone?'green':'Tomato' }"
        >
            {{exportResultsService.itemsCount}}
        </span>
    </span>
    <ul class="export-results p-0 rounded-0"
        ngbDropdownMenu
        aria-labelledby="exportResults"
    >
        <li *ngFor="let item of allExportResults | orderBy:['status', 'createdAt']: ['asc', 'asc']; trackBy: trackByFunction"
            class="border-1 border-bottom ps-2 pe-1"
            ngbDropdownItem
        >
            <div class="d-flex justify-content-between align-items-start">
                <div class="fw-bold">
                    <div class="text-truncate"
                         [ngbTooltip]="item.title?.length > 26 ? item.title : null"
                         container="body"
                    >
                        {{item.title}}
                    </div>
                    <div class="text-success align-items-center"
                         *ngIf="item.status === ExportStatusEnum.done; else processingTmpl">
                        Ready
                        <button class="btn btn-sm btn-light text-success ms-1"
                                (click)="download(item)"
                        >
                            <fa-icon [icon]="'download'"/>
                        </button>
                        <button class="btn btn-sm btn-light text-info ms-1"
                                *ngIf="item?.description as description"
                                [ngbTooltip]="description"
                                container="body"
                        >
                            <fa-icon icon="question-circle"/>
                        </button>
                    </div>
                    <ng-template #processingTmpl>
                        <div class="text-brand d-inline-flex align-items-center">
                            Processing
                            <fa-icon [icon]="'spinner'" [animation]="'spin'" class="px-2 py-1"/>
                        </div>
                    </ng-template>
                </div>
                <div class="">
                    <span class="fs-xs">
                        {{item.createdAt | bdate:'time'}}
                    </span>
                    <button class="btn btn-sm btn-light"
                            (click)="remove(item)"
                            [disabled]="item.loading"
                    >
                        <fa-icon [icon]="'xmark'" *ngIf="!item?.loading"/>
                        <app-inline-loader *ngIf="item?.loading"/>
                    </button>
                </div>
            </div>
        </li>
        <li class="ngbDropdownItem text-center p-2 fs-lg"
            *ngIf="!exportResultsService.itemsCount"
        >
            No exports yet
        </li>
    </ul>
</div>
