import { Component, inject, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Auth } from "@angular/fire/auth";
import { child, Database, onValue, ref, remove } from "@angular/fire/database";
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { CoreService } from '../../services/core.service';
import { ExportService, ExportStatusEnum } from '../../services/export.service';
import { DateUtil } from "../../utils/date/date.util";

const EXPORT_CHANNEL = 'admin_hyperlocal_exports';
const LIFE_DAYS = 1;

interface Notification
{
    createdAt: number;
    path: string;
    ready: boolean;
    exportName: string;
    // read: boolean;
    key?: string;
    description?: string;
    isNotification?: boolean;
}

interface ExportInterface
{
    createdAt: number;
    path: string;
    status: keyof typeof ExportStatusEnum;
    title: string;
    key: string;
    isNotification: boolean;
    description?: string;
    loading?: boolean;
}

@Component({
    selector: 'app-export-results',
    templateUrl: './export-results.component.html',
    styleUrls: ['./export-results.component.scss'],
    providers: [NgbDropdown],
})
export class ExportResultsComponent implements OnInit, OnChanges
{
    @ViewChild('exportResultsDropdown', {static: true}) dropdown: NgbDropdown;
    notificationsMap: Record<string, Notification> = {};

    protected readonly ExportStatusEnum = ExportStatusEnum;

    private db: Database = inject(Database);
    private uid: string;

    constructor(
        public exportResultsService: ExportService,
        private coreService: CoreService,
        private auth: Auth,
    )
    {
        this.uid = this.auth?.currentUser?.uid;
    }

    ngOnInit()
    {
        setTimeout(() => {
            onValue(this.dbRef, async (snapshot) => {
               this.notificationsMap = snapshot.val() || {};

               // Automatically remove notifications older than 1 day
               for (const notification of this.allExportResults) {
                   if (notification.status === ExportStatusEnum.done && DateUtil.datesComparison(
                       'before',
                       DateUtil.addDays(new Date(notification.createdAt * 1000), LIFE_DAYS),
                       new Date()
                   )) {
                       await this.remove(notification);
                   }
               }
               this.exportResultsService.notificationsCount = Object.keys(this.notificationsMap).length;
           })
        });
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (
            changes.logged !== undefined &&
            changes.logged.previousValue !== undefined &&
            changes.logged.currentValue !== changes.logged.previousValue
        ) {
            this.uid = this.auth.currentUser.uid;
        }
    }

    get userId()
    {
        return this.auth.currentUser?.uid || this.uid;
    }

    get dbRef()
    {
        return ref(this.db, `/${EXPORT_CHANNEL}/${this.userId}/`);
    }

    get notifications(): Required<Notification>[]
    {
        return Object.keys(this.notificationsMap).reduce((acc, key) => {
            const notification = this.notificationsMap[key];
            notification.key = key;
            acc.push(notification);
            return acc;
        }, []);
    }

    get allExportResults(): ExportInterface[]
    {
        try {

            return [
                ...this.exportResultsService.items.map((item) => ({
                    ...item,
                    isNotification: false,
                })),
                ...this.notifications.map((notification) => ({
                    key: notification.key,
                    createdAt: notification.createdAt * 1000,
                    path: notification.path,
                    title: notification?.exportName ?? 'Export file',
                    status: notification.ready ? ExportStatusEnum.done : ExportStatusEnum.inProgress,
                    description: notification?.description ?? '',
                    isNotification: true,
                })),
            ];
        } catch (e) {
            console.log('Error while parsing localStorage Export results', e);
            return [];
        }
    }

    trackByFunction = (index, item: ExportInterface): string => item.key;

    download = ({path}: ExportInterface) => this.coreService.downloadFile(path, '');

    async remove(item: ExportInterface)
    {
        if (!item.isNotification) {
            this.exportResultsService.remove(item.key);
            return
        }
        item.loading = true;
        try {
            await this.removeNotification(item.key);
        } finally {
            item.loading = false;
        }
    }

    private removeNotification = (key: string) => remove(child(this.dbRef, key));
}
